.taps_activity_table {
    width: 100%;
    font-weight: normal;
}
.taps_activity_table th {
    font-size: 12px;
    background-color: #00a994;
    color: #FFF;
}

.taps_activity_table tr {
    font-size: 12px;
    font-weight: normal;
    text-align: center;
}

.taps_activity_table td {
    font-weight: normal;
    text-align: left;
}


.barcode_container {
    text-align: center;
    vertical-align: middle;
    padding-left: 30px;
    padding-right: 30px;
}

@font-face {
    font-family: 'Free 3 of 9 Internal';
    src: local('free3of9'), url("./fonts/free3of9.ttf") format("truetype")
}

.barcode {
    font-family: 'Free 3 of 9 Internal', 'Free 3 of 9'; /* sebastienr 2004-12-19: the quotes are necessary for THIS font only. */
    font-weight: normal;
    font-size: 36.0pt;
}

.barcode-text-input {
    width: 100%
}

.barcode-text-container {
    padding: 0
}
